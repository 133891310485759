import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Body from "./components/Body";
import Footer from "./components/Footer";
import Header from "./components/Header";

import "./stylesheets/main.scss";

function App() {
  return (
    <div className="root">
      <Router>
        <Switch>
          <Route path="/">
            <Header />
            <Body />
            <Footer />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
