import React from "react";
import Button from "@material-ui/core/Button";
import SvgIcon from "@material-ui/core/SvgIcon";
import Link from "@material-ui/core/Link";

import { ReactComponent as PlayStore } from "../assets/img/playstore.svg";
import { ReactComponent as AppStore } from "../assets/img/appstore.svg";

function Footer() {
  return (
    <div className="Footer">
      <div className="textSection">
        <p>
          Bookinou est une conteuse audio sans écran qui permet aux enfants de 3
          à 7 ans d’avoir l’histoire audio de CHAQUE livre racontée par une
          personne de son entourage (famille, école). L’objectif est de
          développer le goût des livres et de la lecture. Pour donner aux
          enfants les meilleures chances de réussir leur entrée dans la lecture,
          il existe 4 piliers fondamentaux : le vocabulaire, la mémorisation, la
          compréhension orale et surtout le plaisir de la lecture. Le dispositif
          Bookinou est soutenu par l’Éducation Nationale pour aider à cette
          mission. Plus d’informations et démo :
          <Link
            href="https://www.mybookinou.com/"
            target="_blank"
            rel="noopener"
            className="link"
          >
            {" "}
            bookinou.com
          </Link>
        </p>
        <p className="boldText">
          Télechargez sans plus attendre notre application <br />
          pour enregistrer vos propres histoires{" "}
          <span className="emoji">👇</span>
        </p>
      </div>
      <div className="btnSection">
        <Link
          href="https://play.google.com/store/apps/details?id=com.pimely.mybookinou"
          target="_blank"
          rel="noopener"
          className="link"
        >
          <Button
            variant="contained"
            className="btn"
            startIcon={
              <SvgIcon component={PlayStore} viewBox="0 0 600 476.6" />
            }
          >
            Play Store
          </Button>
        </Link>
        <Link
          href="https://apps.apple.com/us/app/bookinou/id1459349012"
          target="_blank"
          rel="noopener"
          className="link"
        >
          <Button
            variant="contained"
            className="btn"
            startIcon={<SvgIcon component={AppStore} viewBox="0 0 600 476.6" />}
          >
            App Store
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default Footer;
