import React from "react";
import HeaderLogo from "../assets/img/HeaderLogo.png";
import bookinou from "../assets/img/bookinou.png";
import Link from "@material-ui/core/Link";

function Header() {
  return (
    <div className="Header">
      <div className="headerImg">
        <Link
          href="https://www.mybookinou.com/"
          target="_blank"
          rel="noopener"
          className="link"
        >
          <img src={HeaderLogo} alt="logo Bookinou" className="headerLogo" />
        </Link>
        <img src={bookinou} alt="picto Bookinou" className="headerPicto" />
      </div>
    </div>
  );
}

export default Header;
