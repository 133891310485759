import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import Body404 from "./Body404";
import BodyContents from "./BodyContents";
import CryptoJS from "crypto-js/core";
// eslint-disable-next-line no-unused-vars
import AES from "crypto-js/aes";
import queryString from 'query-string';

function Body() {
  const [story, setStory] = useState(undefined);
  const [error, setError] = useState(undefined);
  let location = useLocation();

  const decryptData = useCallback(() => {
    const params = queryString.parse(location.search);
    params.s === null && setError(true);

    const stringToDecrypt = params.s;
    const decrypt = (encryptedData) => {
      try {
        atob(encryptedData.toString());
      } catch (e) {
        setError(true);
        return;
      }

      if (
        encryptedData.length > 100 &&
        atob(encryptedData.toString()).includes("content")
      ) {
        const secret = CryptoJS.enc.Utf8.parse(
          "u35%ZD6)!6dvgZUD:q?Af/8w%27nY4?M"
        );
        const data = JSON.parse(atob(encryptedData)).content;
        const iv = CryptoJS.enc.Utf8.parse(JSON.parse(atob(encryptedData)).iv);
        const decryptedText = CryptoJS.AES.decrypt(data, secret, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });

        return decryptedText.toString(CryptoJS.enc.Utf8);
      } else setError(true);
    };

    const decryptedString = decrypt(stringToDecrypt);

    if (decryptedString) {
      const storyJSON = JSON.parse(decryptedString);
      setStory(storyJSON);
    } else {
      setError(true);
    }
  }, [location]);

  useEffect(() => {
    decryptData();
  }, [decryptData]);

  return (
    <div className="Body">
      {story && <BodyContents story={story} />}
      {error && <Body404 />}
    </div>
  );
}

export default Body;
