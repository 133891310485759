import React from "react";
import headphones from "../assets/img/headphones.png";

function Body404() {
  return (
    <div className="Body">
      <div className="bodyHeader">
        <img src={headphones} alt="casque-audio" className="headphonePicto" />
        <div className="text">
          <p className="title">Erreur</p>
          <p className="detils">
            L'histoire demandée <br />
            n'existe pas
          </p>
        </div>
      </div>
    </div>
  );
}

export default Body404;
