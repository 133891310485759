import React from "react";
import headphones from "../assets/img/headphones.png";
import { PlayButton, Progress, Timer } from "react-soundplayer/components";
import { withCustomAudio } from "react-soundplayer/addons";

function BodyContents({ story }) {
  const AWSSoundPlayer = withCustomAudio((props) => {
    const { trackTitle, author } = props;

    return (
      <div className="awsPlayerWrapper">
        <div className="playerInfos">
          <p className="title">{trackTitle}</p>
          <p className="author">{author}</p>
        </div>
        <div className="awsPlayer">
          <PlayButton {...props} />
          <Progress />
          <Timer {...props} className="duration" />
        </div>
      </div>
    );
  });

  return (
    <>
      <div className="bodyHeader">
        <img src={headphones} alt="casque-audio" className="headphonePicto" />
        <div className="text">
          <p className="title">Écoutez</p>
          <p className="details">
            la nouvelle histoire <br /> enregistrée par {story.pseudo ? story.pseudo : story.role}
          </p>
        </div>
      </div>
      <div className="playerSection">
        <div className="playerWithCover">
          <div className=" playerWrapper">
            <AWSSoundPlayer
              streamUrl={story.soundUrl}
              trackTitle={story.title.slice(0, 35)}
              author={story.pseudo ? story.pseudo : story.role}
              preloadType="auto"
            />
          </div>
          <img
            src={story.pictureUrl}
            alt="story-cover"
            className="storyCover"
          />
        </div>
        <p className="disclaimer">Usage réservé au cadre privé</p>
      </div>
    </>
  );
}

export default BodyContents;
